.customClass {
    position: fixed;
    bottom: 80px;
    right: 5px;
    height: 50px;
    width: 80px;
    color: #012FA7;
    cursor: pointer;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    margin: 2px;
  }
  
  @media screen and (max-width: 768px) {
    .customClass {
      bottom: 100px;
    }
  }
  